<template>
  <v-app-bar
    height="80"
    absolute
    flat
    app
  >
    <v-btn
      v-show="$vuetify.breakpoint.smAndDown"
      class="mr-2"
      depressed
      small
      fab
      @click="SET_DRAWER(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>
    <v-toolbar-title class="hidden-sm-and-down font-weight-light">
      {{ $route.name }}
      <span v-if="title">
        <v-icon>
          mdi-chevron-right
        </v-icon>
        {{ title }}
      </span>
    </v-toolbar-title>
    <v-spacer />
    <v-btn
      color="white"
      depressed
      small
      fab
      @click="logout"
    >
      <v-icon>
        mdi-logout
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DashboardCoreAppBar',
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
  }),
  computed: {
    ...mapState('main', ['title', 'drawer'])
  },
  methods: {
    ...mapActions('main', ['SET_DRAWER']),
    async logout () {
      await this.$auth.signOut()
    }
  }
}
</script>
